import React, { useState } from "react";
import "./settings.sass";
import { v4 as uuid } from "uuid";
import { Layout } from "../layouts";
import Icon from "@mdi/react";
import { mdiRedo, mdiUndo } from "@mdi/js";
import { on } from "events";

export function SettingsPanelSection({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  const [open, setOpen] = React.useState(true);

  return (
    <div className="settings-panel-content-section">
      <h4 onClick={() => setOpen(!open)}>{title}</h4>
      {open && (
        <div className="settings-panel-content-section-inner">{children}</div>
      )}
    </div>
  );
}

export function SettingsPanelOption({
  title,
  children,
  id,
}: {
  title: string;
  children: React.ReactNode;
  id?: string;
}) {
  return (
    <div className="settings-panel-option">
      <label htmlFor={id}>{title}</label>
      <span className="input">{children}</span>
    </div>
  );
}

export function SettingsPanelCheckbox({
  title,
  defaultChecked,
  checked,
  onChange,
}: {
  title: string;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}) {
  const [internChecked, setChecked] = React.useState(defaultChecked);

  const id = uuid();

  return (
    <SettingsPanelOption title={title}>
      <input
        type="checkbox"
        checked={checked !== undefined ? checked : internChecked}
        onChange={(e) => {
          setChecked(e.target.checked);
          onChange?.(e.target.checked);
        }}
        id={id}
      />
    </SettingsPanelOption>
  );
}

function HtmlChangeDetectingInput({
  type = "text",
  defaultValue,
  value,
  onChange,
  onHtmlChange,
  id,
}: {
  type?: string;
  defaultValue?: string;
  value?: string;
  onHtmlChange?: (value: string) => void;
  onChange?: (value: string) => void;
  id?: string;
}) {
  const [initialValue, setInitialValue] = useState(defaultValue ?? "");

  return (
    <input
      type="text"
      value={value}
      onFocus={(e) => setInitialValue(e.target.value)}
      onChange={(e) => {
        onChange?.(e.target.value);
      }}
      onBlur={() => {
        if (initialValue !== value && initialValue !== undefined) {
          onHtmlChange?.(value!!);
          setInitialValue(value!!);
        }
      }}
      id={id}
    />
  );
}

export function SettingsPanelInput({
  title,
  defaultValue,
  value,
  onChange,
  onHtmlChange,
}: {
  title: string;
  defaultValue?: string;
  value?: string;
  onChange?: (value: string) => void;
  onHtmlChange?: (value: string) => void;
}) {
  const [internValue, setValue] = React.useState(defaultValue);

  const id = uuid();

  return (
    <SettingsPanelOption title={title}>
      <HtmlChangeDetectingInput
        value={value !== undefined ? value : internValue}
        onChange={(v) => {
          setValue(v);
          onChange?.(v);
        }}
        onHtmlChange={onHtmlChange}
        id={id}
      />
    </SettingsPanelOption>
  );
}

export function SettingsPanelIntegerInput({
  title,
  defaultValue,
  value,
  onChange,
  onHtmlChange,
}: {
  title: string;
  defaultValue?: string;
  value?: string;
  onChange?: (value: string) => void;
  onHtmlChange?: (value: string) => void;
}) {
  const [internValue, setValue] = React.useState(defaultValue);
  return (
    <SettingsPanelInput
      title={title}
      value={String(value !== undefined ? value : internValue)}
      onChange={(data) => {
        const value = data.replace(/[^0-9]/g, "");
        setValue(value);
        onChange?.(value);
      }}
      onHtmlChange={onHtmlChange}
    />
  );
}

export function SettingsPanelNumberInput({
  title,
  defaultValue,
  value,
  onChange,
  onHtmlChange,
}: {
  title: string;
  defaultValue?: string;
  value?: string;
  onChange?: (value: string) => void;
  onHtmlChange?: (value: string) => void;
}) {
  const [internValue, setValue] = React.useState(defaultValue);
  return (
    <SettingsPanelInput
      title={title}
      value={String(value !== undefined ? value : internValue)}
      onChange={(data) => {
        const value = data.replace(/[^0-9.]/g, "");
        setValue(value);
        onChange?.(value);
      }}
      onHtmlChange={onHtmlChange}
    />
  );
}

export function SettingsPanelButton({
  title,
  buttonText,
  onClick,
}: {
  title: string;
  buttonText?: string;
  onClick?: () => void;
}) {
  buttonText = buttonText ?? title;

  return (
    <SettingsPanelOption title={title}>
      <button onClick={onClick}>{buttonText}</button>
    </SettingsPanelOption>
  );
}

export function SettingsPanelSelect({
  title,
  options,
  defaultValue,
  value,
  onChange,
}: {
  title: string;
  options: string[];
  defaultValue?: string;
  value?: string;
  onChange?: (value: string) => void;
}) {
  const [internValue, setValue] = React.useState(defaultValue);

  return (
    <SettingsPanelOption title={title}>
      <select
        value={value !== undefined ? value : internValue}
        onChange={(e) => {
          setValue(e.target.value);
          onChange?.(e.target.value);
        }}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </SettingsPanelOption>
  );
}

export default function SettingsPanel({
  name,
  setName,
  bpm,
  setBPM,
  length,
  setLength,
  timing,
  setTiming,
  scale,
  setScale,
  clear,
  download,
  save,
  open,
  timelineTimesTwo,
  timelineDivideByTwo,
  deselectAll,
  layoutNames,
  layoutName,
  setLayoutName,
  undo,
  redo,
  undoAvailable,
  redoAvailable,
  requirePush,
}: {
  name: string;
  setName: (name: string) => void;
  bpm: string;
  setBPM: (bpm: string) => void;
  length: string;
  setLength: (length: string) => void;
  timing: string;
  setTiming: (timing: string) => void;
  scale: string;
  setScale: (scale: string) => void;
  clear: () => void;
  download: () => void;
  save: () => void;
  open: () => void;
  timelineTimesTwo: () => void;
  timelineDivideByTwo: () => void;
  deselectAll: () => void;
  setLayoutName(layout: string): void;
  layoutNames: string[];
  layoutName: string;
  undo: () => void;
  redo: () => void;
  undoAvailable: boolean;
  redoAvailable: boolean;
  requirePush: (b: boolean) => void;
}) {
  return (
    <div className="settings-panel">
      <div className="settings-panel-inner">
        <h3>Settings</h3>
        <div className="settings-panel-content">
          <div className="settings-panel-content-inner">
            <SettingsPanelSection title="File">
              <SettingsPanelButton onClick={save} title="Save" />
              <SettingsPanelButton onClick={open} title="Open" />
              <SettingsPanelButton onClick={download} title="Export" />
              <SettingsPanelButton onClick={clear} title="Clear" />
            </SettingsPanelSection>

            <SettingsPanelSection title="General">
              <SettingsPanelSelect
                title="Layout"
                options={layoutNames}
                value={layoutName}
                onChange={setLayoutName}
              />

              <SettingsPanelInput
                title="Name"
                value={name}
                onChange={setName}
                onHtmlChange={() => requirePush(true)}
              />
              <SettingsPanelNumberInput
                title="BPM"
                value={bpm}
                onChange={setBPM}
                onHtmlChange={() => requirePush(true)}
              />
              <SettingsPanelIntegerInput
                title="Length"
                value={length}
                onChange={setLength}
                onHtmlChange={() => requirePush(true)}
              />
              <SettingsPanelInput
                title="Timing"
                value={timing}
                onChange={(data) => {
                  // Should follow format [number]/[number]
                  if (data.match(/^\d+\/\d+$/)) {
                    setTiming(data);
                  }
                }}
                onHtmlChange={() => requirePush(true)}
              />
              <SettingsPanelInput
                title="Scale"
                value={scale}
                onChange={(data) => {
                  // Should follow format [number]/[number]
                  if (data.match(/^\d+\/\d+$/)) {
                    setScale(data);
                  }
                }}
                onHtmlChange={() => requirePush(true)}
              />
            </SettingsPanelSection>

            <SettingsPanelSection title="Edit">
              <SettingsPanelOption title="Undo/Redo">
                <button onClick={undo} disabled={!undoAvailable}>
                  <Icon path={mdiUndo} size={0.6} />
                </button>{" "}
                <button onClick={redo} disabled={!redoAvailable}>
                  <Icon path={mdiRedo} size={0.6} />
                </button>
              </SettingsPanelOption>

              <SettingsPanelOption title="Timeline">
                <button onClick={timelineDivideByTwo}>÷2</button>{" "}
                <button onClick={timelineTimesTwo}>×2</button>
              </SettingsPanelOption>
              <SettingsPanelButton onClick={deselectAll} title="Deselect All" />
            </SettingsPanelSection>
          </div>
        </div>
      </div>
    </div>
  );
}
