import React, { ReactElement } from "react";

import "./changelog.sass";
import { UrlFetcher } from "../layouts";
import Markdown from "react-markdown";

function ChangelogFetcher() {
  const fetch = UrlFetcher();

  const versionIndex = fetch("/changelog/version_index.json").then((res) =>
    res.json()
  ) as Promise<{ version: string; changelog: string | null }[]>;

  async function getChangelog(version: string) {
    const index = await versionIndex;
    const path = index.find((it) => it.version === version)?.changelog;
    if (!path) {
      return null;
    }
    return fetch(path).then((res) => res.text());
  }

  const getVersionIndex = () => versionIndex;
  const getVersionsSince = async (version: string) => {
    const index = await versionIndex;
    const startIndex = index.findIndex((it) => it.version === version);
    return index.slice(startIndex + 1).map((it) => it.version);
  };

  return { getVersionIndex, getChangelog, getVersionsSince };
}

function getCookies() {
  return document.cookie
    .split(";")
    .reduce((acc: Record<string, string>, cookie) => {
      const [key, value] = cookie.split("=");
      acc[key.trim()] = value;
      return acc;
    }, {});
}

function setCookie(name: string, value: string) {
  document.cookie = `${name}=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
}

function getCookie(name: string) {
  return getCookies()[name];
}

export function Changelog() {
  const [lastSeenVersion] = React.useState(
    () => getCookie("lastSeenVersion") || "0.1.0"
  );

  const [changelogFetcher] = React.useState(ChangelogFetcher);

  const [changelogs, setChangelogs] = React.useState<string[][]>([]);
  const [newLatestVersion, setNewLatestVersion] = React.useState<
    string | null
  >();

  React.useEffect(() => {
    changelogFetcher.getVersionIndex().then((index) => {
      const versions = index.map((it) => it.version);
      console.log("Fetching versions since", lastSeenVersion);
      changelogFetcher
        .getVersionsSince(lastSeenVersion)
        .then(async (newVersions) => {
          console.log("New versions", newVersions);

          //setCookie("lastSeenVersion", versions[versions.length - 1]);
          setChangelogs(
            (
              await Promise.all(
                newVersions.map(async (version) => [
                  version,
                  await changelogFetcher.getChangelog(version),
                ])
              )
            )
              .map((e) => {
                console.log(e);
                return e;
              })
              .filter((it) => it[1] !== null) as string[][]
          );
          setNewLatestVersion(versions[versions.length - 1]);
        });
    });
  }, []);

  if (!changelogs.length) {
    return <React.Fragment />;
  }

  return (
    <div className="changelog-area">
      <div className="changelog">
        <h1>Changelog</h1>
        {changelogs.map((version) => (
          <Markdown key={version[0]}>{version[1]}</Markdown>
        ))}

        <div className="footer">
          {newLatestVersion && (
            <button
              onClick={() => {
                setCookie("lastSeenVersion", newLatestVersion);
                setChangelogs([]);
                setNewLatestVersion(null);
              }}
            >
              Mark all as read
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
