import { Launchpad, LaunchpadPad } from "./launchpad";
import "./editor.sass";
import SettingsPanel from "./settings";
import React, { useEffect } from "react";
import Timeline, { TimelineStorage } from "./timeline";
import {
  arrayToRows,
  changeColorInTimeline,
  generateMidi,
  loadTimeline,
  loadTimelineFromFile,
  renderTimeline,
  saveTimeline,
  saveTimelineToFile,
  TimelineStorageFormat,
  timelineTimesTwo,
} from "./functions";
import MidiController from "./midi-controller";
import { getLayout, getLayoutNames, Layout } from "../layouts";
import { Changelog } from "./changelog";

export default function EditorPanel() {
  // Settings

  const [layout, setLayout] = React.useState<Layout | null>(null);
  const [colorPalette, setColorPalette] = React.useState<string[]>([]);

  const [initial] = React.useState(loadTimeline());

  const [name, setName] = React.useState(initial.settings.name);
  const [bpm, setBPM] = React.useState(initial.settings.bpm);
  const [length, setLength] = React.useState(initial.settings.length);
  const [timing, setTiming] = React.useState(initial.settings.timing);
  const [scale, setScale] = React.useState(initial.settings.scale);
  const [timingDividend, timingDivisor] = timing.split("/").map(Number);
  const minUnit = timingDividend / timingDivisor;
  const [layoutName, setLayoutName] = React.useState(
    "Launchpad X Custom Mode 3"
  );

  // Force update

  const [history] = React.useState<TimelineStorageFormat[]>([
    {
      version: "0.1.0",
      settings: { name, bpm, length, timing, scale },
      timeline: initial.timeline.map((color) => ({ ...color })),
    },
  ]);

  const [historyIndex, setHistoryIndex] = React.useState(0);

  function pushHistory() {
    if (historyIndex !== history.length - 1) {
      history.splice(historyIndex + 1);
    }
    history.push({
      version: "0.1.0",
      settings: { name, bpm, length, timing, scale },
      timeline: timeline.map((color) => ({ ...color })),
    });
    setHistoryIndex(historyIndex + 1);
    console.log(history);
  }

  function undo() {
    if (historyIndex > 0) {
      setHistoryIndex(historyIndex - 1);
      const { settings, timeline } = history[historyIndex - 1];
      setName(settings.name);
      setBPM(settings.bpm);
      setLength(settings.length);
      setTiming(settings.timing);
      setScale(settings.scale);
      setTimeline(timeline);
    }
  }

  function redo() {
    if (historyIndex < history.length - 1) {
      setHistoryIndex(historyIndex + 1);
      const { settings, timeline } = history[historyIndex + 1];
      setName(settings.name);
      setBPM(settings.bpm);
      setLength(settings.length);
      setTiming(settings.timing);
      setScale(settings.scale);
      setTimeline(timeline);
    }
  }

  const undoAvailable = historyIndex > 0;
  const redoAvailable = historyIndex < history.length - 1;

  useEffect(() => {
    (async () => {
      const layout = await getLayout(layoutName);
      setLayout(layout);
      const colors = new Array<string>(128).fill("");
      layout?.colorPalette.forEach((color) => {
        colors[color.velocity] = color.color;
      });
      setColorPalette(colors);
    })();
  }, [layoutName]);

  const [_, forceUpdate] = React.useState(0);
  function refresh() {
    forceUpdate((prev) => prev + 1);
  }

  // Timeline

  const [timeline, setTimeline] = React.useState<TimelineStorage>(
    initial.timeline
  );
  const [timelineIndex, setTimelineIndex] = React.useState(0);

  saveTimeline({
    version: "0.1.0",
    settings: { name, bpm: bpm, length, timing, scale },
    timeline,
  });

  const [settingsWidth, setSettingsWidth] = React.useState(
    window.innerWidth / 4
  );
  const [timelineHeight, setTimelineHeight] = React.useState(
    window.innerHeight / 4
  );

  const [mode] = React.useState({
    mode: false,
    mouseDown: false,
  });

  const [selected, setSelected] = React.useState<number[]>([]);

  const [layoutNames, setLayoutNames] = React.useState<string[]>(
    null as unknown as string[]
  );

  useEffect(() => {
    (async () => {
      const layoutNames = await getLayoutNames();
      setLayoutNames(layoutNames);
    })();
  }, []);

  // Keyboard shortcuts
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      // Mac: Cmd + Z, Windows: Ctrl + Z
      if (e.key === "z" && (e.metaKey || e.ctrlKey)) {
        undo();
        e.preventDefault();
      }
      // Mac: Cmd + Y, Windows: Ctrl + Y
      if (e.key === "y" && (e.metaKey || e.ctrlKey)) {
        redo();
        e.preventDefault();
      }
    };

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  });
  const [requirePush, setRequirePush] = React.useState<boolean>(false);

  if (requirePush) {
    pushHistory();
    setRequirePush(false);
  }

  if (layout === null || layoutNames === null) return <div>Loading...</div>;

  const frame = renderTimeline(timeline, timelineIndex, layout);
  const rowedFrame = arrayToRows(frame, layout.width);

  selected.forEach((pad) => {
    frame[pad].selected = true;
  });

  function select(index: number, s: boolean) {
    if (s) {
      setSelected((selected) => {
        if (!selected.includes(index)) return [...selected, index];
        return selected;
      });
    } else setSelected((selected) => selected.filter((id) => id !== index));
  }

  frame.forEach((pad) => {
    pad.onMousedown = function () {
      mode.mode = !this.selected;
      mode.mouseDown = true;
      select(this.id, mode.mode);

      const onMouseUp = () => {
        if (mode.mouseDown) {
          select(this.id, mode.mode);
          mode.mouseDown = false;
        }
        window.removeEventListener("mouseup", onMouseUp);
      };

      window.addEventListener("mouseup", onMouseUp);
    };
    pad.onMouseLeave = function () {
      if (mode.mouseDown) select(this.id, mode.mode);
    };
    pad.onMouseEnter = function () {
      if (mode.mouseDown) select(this.id, mode.mode);
    };
  });

  return (
    <div className="editor-panel">
      <div
        className="editor-panel-editors"
        style={{
          width: `calc(100% - ${settingsWidth}px)`,
          height: `calc(100% - ${timelineHeight}px)`,
        }}
      >
        <div className="editor-panel-preview">
          <div className="editor-panel-preview-inner">
            <h3>Preview</h3>
            {/* <Launchpad lighting sorted pads={frame} /> */}
            <MidiController
              name="Launchpad X Custom Mode 3"
              pads={rowedFrame}
              layout={layout}
              colorPalette={colorPalette}
            />
          </div>
        </div>
        <div className="editor-panel-colors">
          <div className="editor-panel-colors-inner">
            <h3>Colors</h3>

            <Launchpad
              controls
              pads={new Array(128).fill(0).map((_, i) => ({
                velocity: i,
                id: i,
                onClick() {
                  selected.forEach((pad) => {
                    changeColorInTimeline(
                      timeline,
                      pad,
                      this.velocity,
                      timelineIndex,
                      minUnit
                    );
                  });
                  setRequirePush(true);
                },
              }))}
              colors={colorPalette}
            />
          </div>
        </div>
      </div>
      <div
        className="editor-panel-settings"
        style={{
          width: `${settingsWidth}px`,
        }}
      >
        <div
          className="editor-panel-settings-resize"
          onMouseDown={(e) => {
            const initialWidth = settingsWidth;
            const initialX = e.clientX;

            const onMouseMove = (e: MouseEvent) => {
              setSettingsWidth(
                Math.max(
                  4,
                  Math.min(
                    initialWidth - e.clientX + initialX,
                    window.innerWidth
                  )
                )
              );
            };

            const onMouseUp = () => {
              window.removeEventListener("mousemove", onMouseMove);
              window.removeEventListener("mouseup", onMouseUp);
            };

            window.addEventListener("mousemove", onMouseMove);
            window.addEventListener("mouseup", onMouseUp);

            e.preventDefault();
          }}
        ></div>
        <div className="editor-panel-settings-inner">
          <SettingsPanel
            name={name}
            setName={setName}
            bpm={bpm}
            setBPM={setBPM}
            length={length}
            setLength={setLength}
            timing={timing}
            setTiming={setTiming}
            scale={scale}
            setScale={setScale}
            clear={() => {
              const clear = window.confirm("Are you sure you want to clear?");
              if (clear) {
                setTimeline([]);
                setName("Untitled");
                setBPM("120");
                setLength("2");
                setTiming("1/8");
                setScale("2/1");
              }
              setSelected([]);
            }}
            download={() => {
              generateMidi(
                {
                  version: "0.1.0",
                  settings: { name, bpm, length, timing, scale },
                  timeline,
                },
                layout
              );
            }}
            save={() => {
              saveTimelineToFile({
                settings: { name, bpm, length, timing, scale },
                timeline,
              });
            }}
            open={async () => {
              const timeline = await loadTimelineFromFile();
              setTimeline(timeline.timeline);
              setName(timeline.settings.name);
              setBPM(timeline.settings.bpm);
              setLength(timeline.settings.length);
              setTiming(timeline.settings.timing);
              setScale(timeline.settings.scale);
              setSelected([]);
              setRequirePush(true);
            }}
            timelineTimesTwo={() => {
              timelineTimesTwo(timeline);
              setLength((prev) => String(Number(prev) * 2));
              setRequirePush(true);
            }}
            timelineDivideByTwo={() => {
              timelineTimesTwo(timeline);
              setLength((prev) => String(Number(prev) / 2));
              setRequirePush(true);
            }}
            deselectAll={() => {
              setSelected([]);
            }}
            layoutName={layoutName}
            setLayoutName={setLayoutName}
            layoutNames={layoutNames}
            undo={undo}
            redo={redo}
            undoAvailable={undoAvailable}
            redoAvailable={redoAvailable}
            requirePush={setRequirePush}
          />
        </div>
      </div>

      <div
        className="editor-panel-timeline"
        style={{
          height: `${timelineHeight}px`,
          width: `calc(100% - ${settingsWidth}px)`,
        }}
      >
        <div
          className="editor-panel-timeline-resize"
          onMouseDown={(e) => {
            const initialHeight = timelineHeight;
            const initialY = e.clientY;

            const onMouseMove = (e: MouseEvent) => {
              setTimelineHeight(
                Math.max(
                  4,
                  Math.min(
                    initialHeight - e.clientY + initialY,
                    window.innerHeight
                  )
                )
              );
            };

            const onMouseUp = () => {
              window.removeEventListener("mousemove", onMouseMove);
              window.removeEventListener("mouseup", onMouseUp);
            };

            window.addEventListener("mousemove", onMouseMove);
            window.addEventListener("mouseup", onMouseUp);

            e.preventDefault();
          }}
        ></div>
        <div className="editor-panel-timeline-inner">
          <Timeline
            timeline={timeline}
            scale={scale}
            timing={timing}
            width={window.innerWidth - settingsWidth}
            size={Number(length)}
            height={timelineHeight}
            cursorPosition={timelineIndex}
            setCursorPosition={setTimelineIndex}
            bpm={Number(bpm)}
            refresh={refresh}
            layout={layout}
          />
        </div>
      </div>

      <Changelog />
    </div>
  );
}
