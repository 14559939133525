import { Buffer } from "buffer";
import { v4 as uuidv4 } from "uuid";
import pako from "pako";

async function decompressGzipBlobToString(
  compressedBlob: Blob
): Promise<string> {
  const arrayBuffer = await compressedBlob.arrayBuffer();
  const uint8Array = new Uint8Array(arrayBuffer);
  const decompressedData = pako.ungzip(uint8Array, { to: "string" });
  return decompressedData;
}

function JsonParseGzipBlob(blob: Blob): Promise<any> {
  return decompressGzipBlobToString(blob).then(JSON.parse);
}

/**
 * Represents the schema for a custom mode in Launchpad X.
 */
export interface LayoutInfo {
  /**
   * Optional: URI to the schema that this document should conform to.
   */
  $schema?: string;

  /**
   * Optional: Filename of the custom mode (without extension).
   * Must match pattern: ^[a-z0-9-]+$
   */
  filename?: string;

  /**
   * Display-name of the custom mode.
   */
  name: string;

  /**
   * Version of the custom mode.
   * Must match pattern: ^\d+\.\d+\.\d+$
   */
  version: string;

  /**
   * Width of the custom mode.
   * Minimum value: 1
   */
  width: number;

  /**
   * Height of the custom mode.
   * Minimum value: 1
   */
  height: number;

  /**
   * Number of MIDI channels used by the custom mode.
   * Must be between 1 and 16, inclusive.
   */
  midiChannels: number;

  /**
   * Whether the custom mode uses the control channel.
   */
  controllChannel: boolean;

  /**
   * Enlightening percentage for the color palette.
   * Must be between 0 and 100, inclusive.
   */
  lighten: number;

  /**
   * Array of channel mappings.
   * Each item is an array of strings matching pattern: ^((([0-9A-Fa-f]\.)|cc)[0-9a-fA-F]{2})$
   */
  channelMappings: string[][];

  /**
   * Array of color palette entries.
   */
  colorPalette: Array<{
    /**
     * Velocity value.
     * Must be between 0 and 127, inclusive.
     */
    velocity: number;

    /**
     * Color in hex format.
     * Must match pattern: ^#([A-Fa-f0-9]{6})$
     */
    color: string;
  }>;

  /**
   * Base64 encoded layout string.
   */
  layout: string;
}

export class Layout {
  public readonly svg: string;

  constructor(public readonly info: LayoutInfo) {
    this.svg = Buffer.from(info.layout, "base64").toString("utf-8");
  }

  public get colorPalette(): Array<{ velocity: number; color: string }> {
    return this.info.colorPalette;
  }

  public get channelMappings(): string[][] {
    return this.info.channelMappings;
  }

  public get height(): number {
    return this.info.height;
  }

  public get midiChannels(): number {
    return this.info.midiChannels;
  }

  public get name(): string {
    return this.info.name;
  }

  public get version(): string {
    return this.info.version;
  }

  public get width(): number {
    return this.info.width;
  }

  public get controllChannel(): boolean {
    return this.info.controllChannel;
  }
}

export function UrlFetcher(): (url: string) => Promise<{
  blob: () => Promise<Blob>;
  text: () => Promise<string>;
  json: () => Promise<any>;
}> {
  const buffer: Record<string, any> = {};

  return async (url: string) => {
    if (buffer[url]) {
      return {
        blob: () => Promise.resolve(buffer[url] as Blob),
        text: () => (buffer[url] as Blob).text(),
        json: () =>
          (buffer[url] as Blob)
            .text()
            .then(JSON.parse)
            .catch((e) => {
              console.error("Failed to parse JSON for URL:", url, e);
              throw e;
            }),
      };
    }

    const response = await fetch(url);
    const data = await response.blob();
    buffer[url] = data;
    return {
      blob: () => Promise.resolve(data),
      text: () => data.text(),
      json: () =>
        data
          .text()
          .then(JSON.parse)
          .catch((e) => {
            console.error("Failed to parse JSON for URL:", url, e);
            throw e;
          }),
    };
  };
}

async function LayoutFetcher(): Promise<
  [string[], (name: string) => Promise<Layout>]
> {
  const fetchUrl = UrlFetcher();
  const layouts = (await (
    await fetchUrl(`/static/json/layouts/index.json#${uuidv4()}`)
  ).json()) as {
    name: string;
    raw: string;
    compressed: string;
  }[];

  return [
    layouts.map((layout) => layout.name),
    async (name: string) => {
      const url = layouts.find((layout) => layout.name === name)?.compressed;
      if (!url) {
        throw new Error(`Layout not found: ${name}`);
      }

      return new Layout(
        await JsonParseGzipBlob(await (await fetchUrl(url)).blob())
      );
    },
  ];
}

const layoutFetcher = LayoutFetcher();

export const getLayout = async (name: string): Promise<Layout> => {
  const layoutFetchFunction = (await layoutFetcher)[1];
  return await layoutFetchFunction(name);
};

export const getLayoutNames = async () => (await layoutFetcher)[0];
