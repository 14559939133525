import React, { useEffect } from "react";
import "./launchpad.sass";
import Icon from "@mdi/react";
import { mdiMenuLeft, mdiMenuRight } from "@mdi/js";

/**
 * This Component is one Field of the Launchpad
 * @returns {JSX.Element}
 */
export function LaunchpadField({
  velocity,
  id,
  lighting,
  onClick,
  selected,
  onMousedown,
  onMouseup,
  onMouseEnter,
  onMouseLeave,
}: {
  velocity: number;
  id: number;
  lighting: boolean;
  onClick?: () => void;
  onMousedown?: () => void;
  onMouseup?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  selected: boolean;
}) {
  return (
    <div
      className={`launchpad-field velocity-color-${velocity}${
        lighting ? " launchpad-pad-lighting" : ""
      }${selected ? " launchpad-pad-selected" : ""}`}
      onClick={onClick}
      onMouseDown={onMousedown}
      onMouseUp={onMouseup}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span>{id}</span>
    </div>
  );
}

export function LaunchpadControls({
  page,
  pages,
  setPage,
}: {
  page: number;
  pages: number;
  setPage: (page: number) => void;
}) {
  const isFirstPage = page === 1;
  const isLastPage = page === pages;

  return (
    <div className="launchpad-controls">
      <div className="launchpad-controls-inner">
        <button
          className="launchpad-controls-button launchpad-controls-button-left"
          disabled={isFirstPage}
          onClick={!isFirstPage ? () => setPage(page - 1) : undefined}
        >
          <Icon path={mdiMenuLeft} size={0.8} />
        </button>

        <span className="launchpad-controls-page">
          {page} / {pages}
        </span>

        <button
          className="launchpad-controls-button launchpad-controls-button-right"
          disabled={isLastPage}
          onClick={!isLastPage ? () => setPage(page + 1) : undefined}
        >
          <Icon path={mdiMenuRight} size={0.8} />
        </button>
      </div>
    </div>
  );
}

export interface LaunchpadPad {
  velocity: number;
  id: number;
  selected?: boolean;
  onClick?: () => void;
  onMousedown?: () => void;
  onMouseup?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

/**
 * This Component is the Launchpad
 * @returns {JSX.Element}
 */
export function Launchpad({
  pads,
  lighting,
  controls,
  colors,
}: {
  pads?: LaunchpadPad[];
  lighting?: boolean;
  controls?: boolean;
  colors: string[];
}) {
  pads =
    pads ??
    Array(64)
      .fill(0)
      .map((_, i) => ({
        velocity: i,
        id: i,
      }));

  const pages = Math.ceil(pads.length / 64);
  const [page, setPage] = React.useState(1);

  pads = pads.slice((page - 1) * 64, page * 64);

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = colors
      .map(
        (color, index) =>
          `.velocity-color-${index} { background-color: ${color} !important; }`
      )
      .join("\n");

    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [colors]);

  return (
    <div className="launchpad">
      <div className="launchpad-container">
        <div className="launchpad-content-area">
          {pads.map((pad, index) => (
            <LaunchpadField
              key={index}
              velocity={pad.velocity}
              id={pad.id}
              lighting={lighting ?? false}
              selected={pad.selected ?? false}
              onClick={pad.onClick?.bind(pad)}
              onMousedown={pad.onMousedown?.bind(pad)}
              onMouseup={pad.onMouseup?.bind(pad)}
              onMouseEnter={pad.onMouseEnter?.bind(pad)}
              onMouseLeave={pad.onMouseLeave?.bind(pad)}
            />
          ))}
        </div>
        {controls && (
          <LaunchpadControls page={page} pages={pages} setPage={setPage} />
        )}
      </div>
    </div>
  );
}
