import { MidiControllerEvent, MidiData, MidiEvent, writeMidi } from "midi-file";
import { TimelineStorage } from "./timeline";
import { LaunchpadPad } from "./launchpad";
import { Buffer } from "buffer";
import { Layout } from "../layouts";
import { channel } from "diagnostics_channel";

export interface TimelineStorageFormat {
  version: "0.1.0";
  settings: {
    name: string;
    bpm: string;
    length: string;
    timing: string;
    scale: string;
  };
  timeline: TimelineStorage;
}

export function renderTimeline(
  timeline: TimelineStorage,
  index: number,
  layout: Layout
): LaunchpadPad[] {
  const pads = Array(layout.width * layout.height)
    .fill(0)
    .map((_, i) => ({
      velocity: 0,
      id: i,
    }));

  timeline.forEach((color) => {
    if (index >= color.start && index <= color.end) {
      pads[color.id].velocity = color.intensity;
    }
  });

  return pads;
}

export function changeColorInTimeline(
  timeline: TimelineStorage,
  index: number,
  intensity: number,
  timelineIndex: number,
  minUnit: number
) {
  const events = timeline.filter(
    (color) =>
      color.id === index &&
      color.start <= timelineIndex &&
      color.end >= timelineIndex
  );

  if (events.length === 0) {
    if (intensity === 0) return;

    const start = Math.floor(timelineIndex / minUnit) * minUnit;
    timeline.push({
      id: index,
      intensity,
      start,
      end: start + minUnit,
    });
  } else {
    if (intensity === 0) {
      events.forEach((event) => {
        timeline.splice(timeline.indexOf(event), 1);
      });
    } else
      events.forEach((event) => {
        event.intensity = intensity;
      });
  }
}

export function loadTimeline(): TimelineStorageFormat {
  const local = localStorage.getItem("actual");
  if (local) {
    const json = JSON.parse(local);
    if (!json.version) {
      json.version = "0.1.0";
    }
    return json;
  }
  return {
    version: "0.1.0",
    settings: {
      name: "Untitled",
      bpm: "120",
      length: "2",
      timing: "1/8",
      scale: "2/1",
    },
    timeline: [],
  };
}

export function saveTimeline(timeline: TimelineStorageFormat) {
  localStorage.setItem("actual", JSON.stringify(timeline));
}

export function parseMapping(mapping: string) {
  if (mapping === "xxxx") return false;

  const result = /^([a-zA-Z0-9])(c|\.)([a-zA-Z0-9)]{2})$/.exec(mapping);
  if (!result) return null;
  return {
    channel: parseInt(result[1], 16),
    note: parseInt(result[3], 16),
    control: result[2] === "c",
  };
}

export function generateMidi(
  { settings, timeline }: TimelineStorageFormat,
  layout: Layout
) {
  // Test for overlapping notes

  const mappings = layout.channelMappings.flatMap((row) =>
    row.map(
      (mapping) =>
        parseMapping(mapping) as
          | {
              channel: number;
              note: number;
              control: boolean;
            }
          | false
    )
  );

  let overlaps: number[] = [];

  timeline.forEach((note) => {
    const o = timeline.find(
      (other) =>
        other !== note &&
        other.id === note.id &&
        other.start < note.end &&
        other.end > note.start
    );

    if (o) {
      console.log("Overlapping notes detected", { note, o });
      if (!overlaps.includes(note.id)) overlaps.push(note.id);
    }
  });

  if (overlaps.length > 0) {
    console.error("Overlapping notes detected", overlaps);
    alert(
      "Overlapping notes on the following channel(s) detected: " +
        overlaps.join(", ")
    );
    return;
  }

  // Convert to activate and deactivate events

  let events: {
    id: number;
    time: number;
    type: "activate" | "deactivate" | "controll";
    velocity: number;
    delta?: number;
    channel: number;
  }[] = [];

  const [timingDividend, timingDivisor] = settings.timing
    .split("/")
    .map(Number);

  // Beats per tick
  const beatsPerTick = 4;
  const framesPerBeat = 96;

  timeline.forEach((note) => {
    const mapping = mappings[note.id];

    if (!mapping) return;

    events.push({
      id: mapping.note,
      time: Math.round(note.start * framesPerBeat * beatsPerTick),
      type: mapping.control ? "controll" : "activate",
      velocity: note.intensity,
      channel: mapping.channel,
    });
    events.push({
      id: mapping.note,
      time: Math.round(note.end * framesPerBeat * beatsPerTick),
      type: mapping.control ? "controll" : "deactivate",
      velocity: mapping.control ? 0 : 64,
      channel: mapping.channel,
    });
  });

  events = events.sort(
    (a, b) =>
      a.time - b.time ||
      (a.type === "deactivate" && b.type === "activate"
        ? -1
        : b.type === "deactivate" && a.type === "activate"
        ? 1
        : b.velocity - a.velocity) // TODO (For control events)
  );

  // Calculate delta times
  let lastTime = 0;
  events.forEach((event) => {
    event.delta = event.time - lastTime;
    lastTime = event.time;
  });

  const totalFrames = Number(settings.length) * framesPerBeat * beatsPerTick;
  const midiData = {
    header: { format: 0, numTracks: 1, ticksPerBeat: framesPerBeat },
    tracks: [
      [
        { deltaTime: 0, meta: true, type: "trackName", text: settings.name },
        {
          deltaTime: 0,
          meta: true,
          type: "timeSignature",
          numerator: 4,
          denominator: 4,
          metronome: 36,
          thirtyseconds: 8,
        },
        {
          deltaTime: 0,
          meta: true,
          type: "timeSignature",
          numerator: 4,
          denominator: 4,
          metronome: 36,
          thirtyseconds: 8,
        },

        ...events.map((event) =>
          event.type === "controll"
            ? ({
                deltaTime: event.delta!!,
                channel: event.channel,
                type: "controller",
                controllerType: event.id,
                value: event.velocity,
              } satisfies MidiControllerEvent)
            : ({
                deltaTime: event.delta!!,
                channel: event.channel,
                type: event.type === "activate" ? "noteOn" : "noteOff",
                noteNumber: event.id,
                velocity: event.velocity,
              } satisfies MidiEvent)
        ),
        {
          deltaTime: Math.max(0, totalFrames - lastTime),
          meta: true,
          type: "endOfTrack",
        },
      ],
    ],
  } satisfies MidiData;

  const buffer = Buffer.from(writeMidi(midiData));

  console.log(JSON.stringify(events));
  console.log(JSON.stringify(midiData));

  const blob = new Blob([buffer], { type: "audio/midi" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${settings.name}.mid`;
  a.click();
}

export function saveTimelineToFile(timeline: {
  settings: {
    name: string;
    bpm: string;
    length: string;
    timing: string;
    scale: string;
  };
  timeline: TimelineStorage;
}) {
  const blob = new Blob([JSON.stringify(timeline)], {
    type: "application/json",
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${timeline.settings.name}.json`;
  a.click();
}

export function loadTimelineFromFile(): Promise<{
  settings: {
    name: string;
    bpm: string;
    length: string;
    timing: string;
    scale: string;
  };
  timeline: TimelineStorage;
}> {
  let rs: (value: {
    settings: {
      name: string;
      bpm: string;
      length: string;
      timing: string;
      scale: string;
    };
    timeline: TimelineStorage;
  }) => void = () => {};
  let rj: (reason: any) => void = () => {};

  const promise = new Promise<{
    settings: {
      name: string;
      bpm: string;
      length: string;
      timing: string;
      scale: string;
    };
    timeline: TimelineStorage;
  }>((resolve, reject) => {
    rs = resolve;
    rj = reject;
  });

  const input = document.createElement("input");
  input.type = "file";
  input.accept = ".json";
  input.onchange = async () => {
    const file = input.files?.[0];
    if (file) {
      const data = await file.text();
      const timeline = JSON.parse(data);

      if (
        !timeline ||
        !timeline.settings ||
        typeof timeline.settings.name !== "string" ||
        typeof timeline.settings.bpm !== "string" ||
        typeof timeline.settings.length !== "string" ||
        typeof timeline.settings.timing !== "string" ||
        typeof timeline.settings.scale !== "string" ||
        !Array.isArray(timeline.timeline)
      ) {
        return rj("Invalid file format");
      }

      return rs(timeline);
    }
    rj("No file selected");
  };
  input.click();

  return promise;
}

export function timelineTimesTwo(timeline: TimelineStorage) {
  return timeline.map((note) => ({
    ...note,
    start: note.start * 2,
    end: note.end * 2,
  }));
}

export function timelineDivideByTwo(timeline: TimelineStorage) {
  return timeline.map((note) => ({
    ...note,
    start: note.start / 2,
    end: note.end / 2,
  }));
}

export function arrayToRows<T>(array: T[], width: number): T[][] {
  const rows: T[][] = [];
  for (let i = 0; i < array.length; i += width) {
    rows.push(array.slice(i, i + width));
  }
  return rows;
}
