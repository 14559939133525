import React, { useEffect } from "react";
import { getLayout, Layout } from "../layouts";
import { v4 as uuidv4 } from "uuid";
import "./midi-controller.sass";
import { LaunchpadPad } from "./launchpad";
import { sanitize } from "dompurify";

export default function MidiController({
  name,
  pads,
  mouseDown: globalMouseDown,
  mouseUp: globalMouseUp,
  mouseEnter: globalMouseEnter,
  mouseLeave: globalMouseLeave,
  layout,
  colorPalette,
}: {
  name: string;
  pads: LaunchpadPad[][];
  mouseLeave?: () => void;
  mouseEnter?: () => void;
  mouseDown?: () => void;
  mouseUp?: () => void;
  layout: Layout | null;
  colorPalette: string[];
}) {
  const hexColors = pads.map((e) => e.map((c) => colorPalette[c.velocity]));

  const id = `midi-controller-${uuidv4()}`;

  const style = hexColors.flatMap((e, i) => {
    return e.map((c, j) => `#${id} .pad-${i + 1}-${j + 1} { fill: ${c}; }`);
  });

  const [state] = React.useState({
    hovering: null as string | null,
  });

  pads.forEach((row, i) => {
    row.forEach((pad, j) => {
      if (pad.selected) {
        style.push(
          `#${id} .pad-${i + 1}-${j + 1} { stroke: #fff; stroke-width: 4; }`
        );
      }
    });
  });

  /*
  useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.innerHTML = style.join("\n");
    styleElement.classList.add("midi-controller-style");
    document.head.appendChild(styleElement);
    return () => {
      document.head.removeChild(styleElement);
    };
  });

  */

  useEffect(() => {
    function parsePadIndexes(pad: string): [number, number] {
      const regexResult = /^pad-([0-9a-fA-F])-([0-9a-fA-F])$/.exec(pad);
      if (regexResult === null) {
        return [-1, -1];
      }
      return [
        parseInt(regexResult[1], 16) - 1,
        parseInt(regexResult[2], 16) - 1,
      ];
    }

    function enterPad(e: MouseEvent, pad: string) {
      const [x, y] = parsePadIndexes(pad);
      pads[x][y].onMouseEnter?.();
    }

    function leavePad(e: MouseEvent, pad: string) {
      const [x, y] = parsePadIndexes(pad);
      pads[x][y].onMouseLeave?.();
    }

    function mouseDown(e: MouseEvent) {
      globalMouseDown?.();
      if (!state.hovering) return;
      const [x, y] = parsePadIndexes(state.hovering);
      pads[x][y].onMousedown?.();
    }

    function mouseUp(e: MouseEvent) {
      globalMouseUp?.();
      if (!state.hovering) return;
      const [x, y] = parsePadIndexes(state.hovering);
      pads[x][y].onMouseup?.();
    }

    function mouseLeave(e: MouseEvent) {
      globalMouseLeave?.();
      if (state.hovering === null) return;
      leavePad(e, state.hovering);
      state.hovering = null;
    }

    function mouseEnter(e: MouseEvent) {
      globalMouseEnter?.();
    }

    function mouseMove(e: MouseEvent) {
      let target = e.target as HTMLElement | null;
      let tc: string | null = null;

      while (target) {
        const classes = target.classList;

        let breakLoop = false;

        for (let i = 0; i < classes.length; i++) {
          const className = classes.item(i);
          if (/^pad-[0-9a-fA-F]-[0-9a-fA-F]$/.test(className ?? "")) {
            tc = className;
            breakLoop = true;
            break;
          }
        }

        if (breakLoop) break;

        target = target.parentElement;
      }

      if (target === null || tc === null) {
        if (state.hovering === null) return;
        leavePad(e, state.hovering);
        state.hovering = null;
      }

      if (state.hovering === tc) return;
      if (state.hovering !== null) leavePad(e, state.hovering);
      state.hovering = tc;
      enterPad(e, tc as string);
    }

    const element = document.getElementById(id);

    element?.addEventListener("mousedown", mouseDown);
    element?.addEventListener("mousemove", mouseMove);
    element?.addEventListener("mouseleave", mouseLeave);
    element?.addEventListener("mouseenter", mouseEnter);
    element?.addEventListener("mouseup", mouseUp);

    return () => {
      element?.removeEventListener("mousedown", mouseDown);
      element?.removeEventListener("mousemove", mouseMove);
      element?.removeEventListener("mouseleave", mouseLeave);
      element?.removeEventListener("mouseenter", mouseEnter);
      element?.removeEventListener("mouseup", mouseUp);
    };
  });

  if (!layout) {
    return null;
  }

  // Prevent XSS attacks
  const innerHTML = layout.svg ? sanitize(layout.svg) : "";

  return (
    <div className="midi-controller" id={id}>
      <style>{style.join("\n")}</style>
      <div
        className="midi-controller-inner"
        id={id}
        dangerouslySetInnerHTML={{ __html: innerHTML }}
      ></div>
    </div>
  );
}
