import React, { useEffect } from "react";

import "./timeline.sass";
import Icon from "@mdi/react";
import { mdiMenuDown, mdiPause, mdiPlay } from "@mdi/js";
import { Layout } from "../layouts";

export interface LaunchpadColor {
  /**
   * The color of the pad
   */
  intensity: number;

  /**
   * The ID of the pad
   */
  id: number;

  /**
   * The starting point of the color
   */
  start: number;

  /**
   * The ending point of the color
   */
  end: number;
}

export type TimelineStorage = LaunchpadColor[];

export function TimelinePadColor({
  color,
  unitWidth,
  minUnitWidth,
  minUnit,
  size,
  refresh,
}: {
  color: LaunchpadColor;
  unitWidth: number;
  minUnitWidth: number;
  minUnit: number;
  size: number;
  refresh: () => void;
}) {
  return (
    <div
      className={`timeline-color velocity-color-${color.intensity}`}
      style={{
        left: `${unitWidth * color.start}px`,
        width: `${Math.max(
          minUnitWidth,
          unitWidth * (color.end - color.start)
        )}px`,
      }}
    >
      <div className="timeline-color-resize-inner">
        <div
          className="timeline-color-resize-left"
          onMouseDown={(e) => {
            const initialX = e.clientX;
            const initialUnit = color.start;

            const onMouseMove = (e: MouseEvent) => {
              const movement = e.clientX - initialX;
              const units = Math.round(movement / minUnitWidth);
              color.start = Math.max(
                0,
                Math.min(initialUnit + units * minUnit, color.end - minUnit)
              );

              refresh();
            };

            const onMouseUp = () => {
              window.removeEventListener("mousemove", onMouseMove);
              window.removeEventListener("mouseup", onMouseUp);
            };

            window.addEventListener("mousemove", onMouseMove);
            window.addEventListener("mouseup", onMouseUp);

            e.preventDefault();
          }}
        />
        <div
          className="timeline-color-resize-right"
          onMouseDown={(e) => {
            const initialX = e.clientX;
            const initialUnit = color.end;

            const onMouseMove = (e: MouseEvent) => {
              const movement = e.clientX - initialX;
              const units = Math.round(movement / minUnitWidth);
              color.end = Math.max(
                color.start + minUnit,
                Math.min(initialUnit + units * minUnit, 64)
              );

              refresh();
            };

            const onMouseUp = () => {
              window.removeEventListener("mousemove", onMouseMove);
              window.removeEventListener("mouseup", onMouseUp);
            };

            window.addEventListener("mousemove", onMouseMove);
            window.addEventListener("mouseup", onMouseUp);

            e.preventDefault();
          }}
        />
      </div>
    </div>
  );
}

export function TimelinePad({
  timelineContentWidth,
  timeline,
  unitWidth,
  minUnitWidth,
  id,
  minUnit,
  size,
  refresh,
}: {
  timelineContentWidth: number;
  timeline: LaunchpadColor[];
  unitWidth: number;
  minUnitWidth: number;
  id: number;
  minUnit: number;
  size: number;
  refresh: () => void;
}) {
  return (
    <div
      className="timeline-pad"
      style={{
        width: `${timelineContentWidth + 16}px`,
      }}
    >
      <div className="pad-label">{(id + 1).toString().padStart(2, "0")}</div>
      <div className="pad-timeline">
        {timeline
          .filter((color) => color.id === id)
          .map((color, i) => (
            <TimelinePadColor
              key={i}
              color={color}
              unitWidth={unitWidth}
              minUnitWidth={minUnitWidth}
              minUnit={minUnit}
              size={size}
              refresh={refresh}
            />
          ))}
      </div>
    </div>
  );
}

export default function Timeline({
  timeline,
  timing,
  scale,
  width,
  size,
  cursorPosition,
  bpm,
  setCursorPosition,
  refresh,
  layout,
}: {
  timeline: TimelineStorage;
  timing: string;
  scale: string;
  width: number;
  size: number;
  height: number;
  cursorPosition: number;
  bpm: number;
  setCursorPosition: React.Dispatch<React.SetStateAction<number>>;
  refresh: () => void;
  layout: Layout;
}) {
  const [timingDividend, timingDivisor] = timing.split("/").map(Number);
  const [scaleDividend, scaleDivisor] = scale.split("/").map(Number);

  const timelineWidth = width - 20 - 24;

  const unitWidth = (timelineWidth / scaleDividend) * scaleDivisor;
  const minUnit = timingDividend / timingDivisor;
  const minUnitWidth = unitWidth * minUnit;

  const timelineShows = scaleDividend / scaleDivisor;

  const numPages = size / timelineShows;

  const timelineContentWidth = numPages * timelineWidth;

  const tpm = bpm / timingDivisor;
  const tpms = tpm / 60_000;

  const [scroll, setScroll] = React.useState(0);
  const [playing, setPlaying] = React.useState(false);
  const [cancelFunction, setCancelFunction] = React.useState<
    (() => void) | null
  >(null);

  function togglePlay() {
    setPlaying(!playing);

    if (!playing) {
      let lastFrame = Date.now();
      const initialCursorPosition = cursorPosition;
      let cp = cursorPosition;
      let cancelFunction: () => void = () => {};
      const interval = setInterval(() => {
        const timeSinceLastFrame = Date.now() - lastFrame;
        lastFrame = Date.now();

        cp += tpms * timeSinceLastFrame;
        if (cp >= size) {
          return cancelFunction();
        }

        setCursorPosition(cp);
      }, 20);

      cancelFunction = () => {
        clearInterval(interval);
        setPlaying(false);
        setCancelFunction(null);
        setCursorPosition(initialCursorPosition);
      };

      setCancelFunction(() => cancelFunction);
    } else {
      cancelFunction?.();
    }
  }

  useEffect(() => {
    const windowKeyListeners = (e: KeyboardEvent) => {
      if (e.key === " ") {
        togglePlay();
      }
    };

    window.addEventListener("keydown", windowKeyListeners);

    return () => {
      window.removeEventListener("keydown", windowKeyListeners);
    };
  });

  return (
    <div
      className="timeline"
      onScroll={(e) => {
        setScroll(e.currentTarget.scrollTop);
      }}
    >
      <div
        className="timeline-inner"
        style={{
          width: `${timelineContentWidth}px`,
        }}
      >
        {new Array(layout.width * layout.height).fill(0).map((_, i) => (
          <TimelinePad
            key={i}
            timelineContentWidth={timelineContentWidth}
            timeline={timeline}
            unitWidth={unitWidth}
            minUnitWidth={minUnitWidth}
            minUnit={minUnit}
            id={i}
            size={size}
            refresh={refresh}
          />
        ))}

        <div
          className="timeline-header"
          style={{
            top: `${scroll}px`,
          }}
        >
          <button className="timeline-header-play-pause" onClick={togglePlay}>
            <Icon path={playing ? mdiPause : mdiPlay} size={0.7} />
          </button>
          <div
            className="timeline-header-inner"
            style={{
              top: `${scroll}px`,
            }}
          >
            <div
              className="timeline-header-cursor"
              style={{
                left: `${unitWidth * cursorPosition}px`,
              }}
              onMouseDown={(e) => {
                const initialX = e.clientX;
                const initialPosition = cursorPosition;

                const onMouseMove = (e: MouseEvent) => {
                  const movement = e.clientX - initialX;
                  const units = movement / unitWidth;
                  setCursorPosition(
                    Math.max(0, Math.min(initialPosition + units, size))
                  );
                };

                const onMouseUp = () => {
                  window.removeEventListener("mousemove", onMouseMove);
                  window.removeEventListener("mouseup", onMouseUp);
                };

                window.addEventListener("mousemove", onMouseMove);
                window.addEventListener("mouseup", onMouseUp);
              }}
            >
              <Icon path={mdiMenuDown} size={1.4} />
            </div>

            {new Array(Math.ceil(size)).fill(0).map((_, i) => (
              <div
                key={i}
                className="timeline-header-unit"
                style={{
                  width: `${unitWidth - 2}px`,
                }}
              >
                {new Array(1 / minUnit).fill(0).map((_, i) => (
                  <div
                    key={i}
                    className="timeline-header-unit-sub"
                    style={{
                      width: `${minUnitWidth - 2}px`,
                    }}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
